import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import { useBodyClass } from "../../utils/bodyClassUtils";
import { addImage } from "../../utils/imageUtils";

export default ({ data }) => {
  // data.content should be filled in, others require null check. If validation is wrong return null (if data is empty)
  const blockdata = data.content.general;
  const gallery = data.content.gallery;
  const [isImageGalleryActive, setIsImageGalleryActive] = useState(false);
  useBodyClass(isImageGalleryActive, "has-overlay");

  const handleScreenchange = () => {
    setIsImageGalleryActive(!isImageGalleryActive);
  };

  if (!(blockdata && gallery)) {
    return null;
  }
  
  let images = [];

  if (gallery.imageList) {
    gallery.imageList.forEach(image => {
      addImage(images, image.url);
    });
  }

  return (
    <section className="page-block-textgallery--left">
      <div className="page-block-textgallery__container">
        <div className="page-block-textgallery__body">
          <div className="page-block-textgallery__image">
            <ImageGallery
              items={images}
              useBrowserFullscreen={false}
              onScreenChange={handleScreenchange}
              showPlayButton={false}
            />
          </div>
          <div className="page-block-textgallery__description">
            <h2>{blockdata.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: blockdata.text }} />
          </div>
        </div>
      </div>
    </section>
  );
};
